var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-layout',{attrs:{"id":"components-layout-demo-top-side-2"}},[_c('a-affix',{style:({ zIndex: '99999' }),attrs:{"offset-top":0}},[_c('a-layout-header',{staticClass:"header"},[_c('div',{staticClass:"logo"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/logo.png"),"alt":"logo"},on:{"click":_vm.logoTap}})]),_c('Header')],1)],1),_c('a-layout',[_c('a-affix',{style:({ zIndex: '0', minWidth: '200px', flexShrink: '0' }),attrs:{"offset-top":65}}),_c('a-layout',{style:({
          padding: _vm.$route.meta.hideTitle ? '' : '0 24px 24px',
          overflow: 'hidden',
        })},[(!_vm.$route.meta.hideTitle)?_c('breadcrumb'):_vm._e(),_c('a-spin',{attrs:{"size":"large","spinning":_vm.$store.state.loading.contentLoading}},[_c('a-layout-content',{staticClass:"user-layout-content",style:({
              background: '#fff',
              borderRadius: '4px',
            })},[_c('router-view')],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }